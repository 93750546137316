<template>
  <div>
    <div id="kt_header" style="" class="header align-items-stretch">
      <div
        class="
          container-fluid
          d-flex
          align-items-stretch
          justify-content-between
        "
      >
        <div
          class="d-flex align-items-center d-lg-none ms-n1 me-2"
          title="Show aside menu"
        >
          <div
            class="
              btn btn-icon btn-active-color-primary
              w-30px
              h-30px
              w-md-40px
              h-md-40px
            "
            id="kt_aside_mobile_toggle"
          >
            <span class="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                  fill="currentColor"
                />
                <path
                  opacity="0.3"
                  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <router-link to="/" class="d-lg-none">
            <img
              alt="Logo"
              src="../assets/img/is-logo-original.png"
              class="h-30px"
            />
          </router-link>
        </div>
        <div
          class="
            d-flex
            align-items-stretch
            justify-content-between
            flex-lg-grow-1
          "
        >
          <div class="d-flex align-items-stretch" id="kt_header_nav">
            <div
              class="header-menu align-items-stretch"
              data-kt-drawer="true"
              data-kt-drawer-name="header-menu"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '250px'}"
              data-kt-drawer-direction="end"
              data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
            >
              <div
                class="
                  menu
                  menu-lg-rounded
                  menu-column
                  menu-lg-row
                  menu-state-bg
                  menu-title-gray-700
                  menu-state-title-primary
                  menu-state-icon-primary
                  menu-state-bullet-primary
                  menu-arrow-gray-400
                  fw-bold
                  my-5 my-lg-0
                  align-items-stretch
                "
                id="#kt_header_menu"
                data-kt-menu="true"
              >
                <Toolbars />
              </div>
            </div>
          </div>
          <div class="d-flex align-items-stretch flex-shrink-0">
            <div class="d-flex align-items-center ms-1 ms-lg-3">
										<a href="#" class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
											<i class="ki-outline ki-night-day theme-light-show fs-1"></i>
											<i class="ki-outline ki-moon theme-dark-show fs-1"></i>
										</a>
										<div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px" data-kt-menu="true" data-kt-element="theme-mode-menu" style="">
											<div class="menu-item px-3 my-0">
												<a href="#" class="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light">
													<span class="menu-icon" data-kt-element="icon">
														<i class="ki-outline ki-night-day fs-2"></i>
													</span>
													<span class="menu-title">Açık</span>
												</a>
											</div>
											<div class="menu-item px-3 my-0">
												<a href="#" class="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="dark">
													<span class="menu-icon" data-kt-element="icon">
														<i class="ki-outline ki-moon fs-2"></i>
													</span>
													<span class="menu-title">Koyu</span>
												</a>
											</div>
											<div class="menu-item px-3 my-0">
												<a href="#" class="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="system">
													<span class="menu-icon" data-kt-element="icon">
														<i class="ki-outline ki-screen fs-2"></i>
													</span>
													<span class="menu-title">Sistem</span>
												</a>
											</div>
										</div>
									</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Toolbars from "@/components/toolbars.vue";
export default {
  components: { Toolbars },
  data() {
    return {
      loading: false,
      userData: [],
      dataCount: "",
      tableData: [],
      params: [],
      editData: [],
      page: 1,
      pageData: [],
      limit: 10,
      valueId: [],
      bayi_id: "",
      states: [],
      baseUrl: "https://api.bayiportal.com/uploads/",
      column_array_id: [],
      column_array_id_query: [],
      column_set_id: [],
      filterAct: {
        filters: {
          kayit_durum_id: {
            type: 1,
            guiType: "multiselect",
            filter: ["3"],
            description: "",
            columnName: "kayit_durum_id",
            filterColumnNames: ["kayit_durum_id"],
          },
        },
      },
      formatter: new Intl.NumberFormat("tr-TR", {
        //style: 'currency',
        currency: "TRY",
        minimumFractionDigits: 2,
      }),
      theme: "light",
    };
  },
  methods: {
    prev() {
      this.page--;
      this.getTableData();
    }, //Bir önceki sayfaya gönder
    next() {
      this.page++;
      this.getTableData();
    }, //Bir sonraki sayfaya gönder
    getUsers() {
      this.loading = true;
      axios
        .get(this.$store.getters.getToken + "/getLoggedInUserInfo")
        .then((response) => {
          if (response.data.status == "error") {
            window.location.href = "/login";
          } else {
            this.userData = response.data.data;
            this.column_array_id =
              response.data.data.auths.tables.bildirim.lists[0];
            this.column_array_id_query =
              response.data.data.auths.tables.bildirim.queries[0];
            this.column_set_id =
              response.data.data.auths.tables.istek.creates[0];
            this.bayi_id = response.data.data.user.id;
            this.getTableData();
            setInterval(() => {
              this.getTableData();
            }, 10000);
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
          localStorage.removeItem("frontToken");
          window.location.href = "/login";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTableData() {
      // this.tableData = JSON.parse(localStorage.getItem('kayitlar')).records;
      // console.log(this.tableData);

      this.loading = true;
      this.params = {
        page: this.page,
        limit: this.limit,
        column_array_id: this.column_array_id,
        column_array_id_query: this.column_array_id_query,
        sorts: {},
        filters: {},
        editMode: false,
        liveDataMode: true,
        columnNames: [],
        filterColumnNames: [],
      };
      axios
        .post(this.$store.getters.getToken + "/tables/bildirim", {
          params: JSON.stringify(this.params),
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.dataCount = res.data.data.all_records_count;
            this.tableData = res.data.data.records;
            this.pageData = res.data.data;
            // localStorage.setItem(this.tableName, JSON.stringify(this.data));
            for (const [key, val] of Object.entries(res.data.data.records)) {
              // console.log("key",key)
              // console.log("val",val.id)
              // console.log(val.state)
              this.valueId = val.id;
              this.states = val.state;

              // this.yilData[key]=val;
            }
          }
          this.stateEditData();
        })
        .catch((err) => {
          console.log(err.response?.data.data.message);
          if (err.response?.data.data.message == "fail.token") {
            this.$store.dispatch("logOut");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeState() {
      let paramet = new FormData();
      paramet.append("bayi_id", this.bayi_id);
      paramet.append("column_set_id", this.column_set_id);
      axios
        .post(this.$store.getters.getToken + "/tables/istek/store", paramet)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    logOut() {
      this.$store.dispatch("logOut");
    },
    darkLight() {
      if (this.theme == "light") {
        this.theme = "dark";
        localStorage.setItem("theme", this.theme);
      } else {
        this.theme = "light";
        localStorage.setItem("theme", this.theme);
      }
    },
  },
  created() {
    this.getUsers();
    localStorage.setItem("theme", this.theme);
  },
};
</script>

<style>
.bgimage {
  background-image: url("../assets/media/misc/pattern-1.jpg");
}
.bgimg {
  background-image: url("../assets/media/misc/pattern-1.jpg");
}
[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: var(--theme-color-1);
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}
</style>