<template>
  <div class="d-flex">
    <div
      id="kt_toolbar_container"
      class="container-fluid d-flex align-items-center"
    >
      <div class="flex-grow-1 flex-shrink-0 me-5">
        <div
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          class="
            page-title
            d-flex
            align-items-center
            flex-wrap
            me-3
            mb-5 mb-lg-0
          "
        >
          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {{ this.$route.name }}
            <span class="h-20px border-gray-200 border-start ms-3 mx-2"></span>
            <ul
              v-if="this.$route.path == '/'"
              class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
            >
              <li class="breadcrumb-item text-muted">
                <router-link
                  :to="this.$route.path"
                  class="text-muted text-hover-primary"
                  >{{ this.$route.name }}</router-link
                >
              </li>
            </ul>
            <ul
              v-else-if="
                this.$route.path == '/list' ||
                this.$route.path == '/profile' ||
                this.$route.path == '/pricing' ||
                this.$route.path == '/financial' ||
                this.$route.path == '/education' ||
                this.$route.path == '/support' ||
                this.$route.path == '/campaigns'
              "
              class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
            >
              <li class="breadcrumb-item text-muted">
                <router-link to="/" class="text-muted text-hover-primary"
                  >Ana Sayfa</router-link
                >
              </li>
              <li class="breadcrumb-item">
                <span class="bullet bg-gray-200 w-5px h-2px"></span>
              </li>
              <li class="breadcrumb-item text-dark">{{ this.$route.name }}</li>
            </ul>
            <ul
              v-else-if="this.$route.path == '/bayiportal'"
              class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
            >
              <!--begin::Item-->
              <li class="breadcrumb-item text-muted">
                <router-link to="/" class="text-muted text-hover-primary"
                  >Ana Sayfa</router-link
                >
              </li>
              <li class="breadcrumb-item">
                <span class="bullet bg-gray-200 w-5px h-2px"></span>
              </li>
              <li class="breadcrumb-item text-muted">
                <router-link to="/list" class="text-muted text-hover-primary"
                  >Kayıtlar</router-link
                >
              </li>
              <li class="breadcrumb-item">
                <span class="bullet bg-gray-200 w-5px h-2px"></span>
              </li>
              <li class="breadcrumb-item text-dark">{{ this.$route.name }}</li>
            </ul>
          </h1>
        </div>
      </div>
      {{ new Date().getDate() }} {{ this.month }}
      {{ new Date().getFullYear() }}, {{ this.days }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      days: "",
      month: "",
    };
  },
  methods: {
    setMonth() {
      let aylar = [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık",
      ];
      let gunler = [
        "Pazar",
        "Pazartesi",
        "Salı",
        "Çarşamba",
        "Perşembe",
        "Cuma",
        "Cumartesi",
      ];
      this.month = aylar[new Date().getMonth()];
      this.days = gunler[new Date().getDay()];
    },
  },
  created() {
    this.setMonth();
  },
};
</script>