import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import store from "./store";

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

axios.defaults.baseURL = "https://muhasebe.ishesap.com/api/v1/";

let app = createApp(App);

app.config.globalProperties.imgBaseUrl = "https://muhasebe.ishesap.com/";

app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(VueToast);

app.config.globalProperties.getImages = (image) => {
    if (image == undefined || image == "[]") {
      return [];
    } else {
      const img = JSON.parse(image)[0];
      return app.config.globalProperties.imgBaseUrl + img.disk + "/" + img.destination_path + "m_" + img.file_name;
    }
  };

app.mount("#app");
// app.use(VueJivosite, { widgetId: "JnwJIhy8mD" });


