<template>
    <div class="bgs">
        <div class="d-flex flex-column flex-center flex-column-fluid">
        <div class="d-flex flex-column flex-center text-center col-md-12 px-15 py-5">
            <div class="card card-flush col-md-12 py-5">
                <div class="card-body py-15 py-lg-20">
                    <div class="mb-7">
                            <img alt="Logo" src="../assets/img/is-logo-original.png" class="h-40px">
                    </div>
                    <h1 class="fw-bolder text-gray-900 mb-5">Kaydınız Oluşturuldu</h1>
                    <div class="fw-semibold fs-3 text-gray-500 mb-7">Şifreniz oluşturuldu ve mail adresinize gönderildi. Form incelenip onaylandıktan sonra mail adresiniz ile bilgilendirileceksiniz.</div>
                    <!-- <div class="mb-0">
                        <img src="../assets/img/coming-soon.png" class="mw-100 mh-300px theme-light-show" alt="">
                    </div> -->
                    <div class="mb-0">
                        <router-link to="/login" class="btn btn-sm btn-primary">
                            Giriş Sayfasına Git
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style>
.bgs {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../assets/media/patterns/pattern-1.jpg);
}
</style>