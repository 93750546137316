import Vuex from "vuex"
import axios from "axios"
import Swal from 'sweetalert2';

const store = new Vuex.Store({
    state: { //değişkenlerin tutulacağı alan
        token: "",
        userData: {},
        loginData: {},
        companyData: [],
    },
    mutations: { //değişkenlerin değiştirilmesi için kullanılan fonksiyonlar
        setToken(state, token) {
            state.token = token;
            localStorage.setItem("frontToken", token);
            let control = setTimeout(() => {
                if (localStorage.getItem("frontToken") != null) {
                    
                    clearInterval(control);
                }
            }, 100);
        },
        setUserData(state, userData) {
            state.userData = userData;
            localStorage.setItem("userData", JSON.stringify(userData));
            window.location.href = "/";
        },
        setCompanyData(state, data) {
            state.companyData = data;
        },
        setLoginData(state, data) {
            state.loginData = data;
        },
    },
    actions: {
        verification(store, data) {
            axios.post("https://ipapi.co/json/").then((res) => {
                data["clientInfo"] = {
                    browser: res.data,
                    device: navigator.platform,
                    type: "web",
                    name: "",
                    dateTime: new Date().toISOString(),
                };

                store.commit("setLoginData", data);
                axios
                    .post("login", data)
                    .then((res) => {
                        if (typeof res.data.data.token == "object") {
                            // router.push("/verification");
                            data["token"] = res.data.data.token;
                            store.commit("setLoginData", data);
                        } else {
                            // document.getElementById("page-loader").style.display = "block";
                            document.getElementById("app").style.display = "none";
                            store.commit("setToken", res.data.data.token);
                            store.dispatch("userDataApi");
                        }
                    })
                    .catch((err) => {
                        if(err.response.data.data.message == 'mail.or.password.incorrect') toastr.error("Kullanıcı adı veya şifre hatalıdır.");
                        console.log("Hata");
                    });
            });
        },
        login(store, authData) {
            axios.post("https://ipapi.co/json/").then((res) => {
                authData["clientInfo"] = {
                    browser: res.data,
                    device: navigator,
                    type: "web",
                    dateTime: new Date().toISOString(),
                };
                axios
                    .post("otp", authData)
                    .then((response) => {
                        if (response.data.status) {
                            // document.getElementById("page-loader").style.display = "block";
                            document.getElementById("app").style.display = "none";
                            store.commit("setToken", response.data.data.token);
                            console.log("Başarılı")
                            localStorage.removeItem("loginData");
                        }
                    })
                    .catch(() => {
                        console.log("Hata");
                    });
            });
        },
        getToken(state) {
            if (state.token) {
                return state.token;
            } else {
                if (localStorage.getItem("frontToken")) {
                    state.token = localStorage.getItem("frontToken");
                    return state.token;
                } else {
                    return null;
                }
            }
        },
        userDataApi(store) {
            axios.post(store.getters.getToken + "/getLoggedInUserInfo")
                .then((res) => {
                    if (res.data.data.user["kullanici_tipi_id"] != 8) {
                        this.$router.push("/");
                    } else if (res.data.code == "400") {
                        this.$router.push("/");
                    } else if(res.data.data.user["kullanici_tipi_id"] == 8) {
                        console.log(res.data.data)
                        store.commit("setUserData", res.data.data);
                        return true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    
                    localStorage.removeItem("frontToken");
                    window.location.href = "/";
                });
        },
        compDataApi(store) {
            if (store.getters.getUserData.user?.department_id == undefined) return;
            else {
                services
                    .show("departments", store.getters.getUserData.user?.department_id)
                    .then((res) => {
                        store.commit("setCompData", res.data.data.record);
                    })
                    .catch(() => {
                        //store.dispatch("logOut");
                    });
            }
        },
        logOut(store) {
            let params = {
                column_set_it: store.getters.getUserData.auths.tables['users']?.edits?.[0],
            };
            axios.post(store.getters.getToken + "/tables/users/" + store.getters.getUserData.user?.id + "/edit", {
                params: JSON.stringify(params)
            }).then((res) => {
              var tokens = JSON.parse(res.data.data.record.tokens);
              tokens.splice(tokens.indexOf(this.getToken), 1);
              let par = {
                column_set_id: store.getters.getUserData.auths.tables["users"].edits[0],
                email: store.getters.getUserData.user.email,
                tc: store.getters.getUserData.user.tc,
                tokens: JSON.stringify(tokens)
              }
              axios.post(store.getters.getToken + "/tables/users/" + store.getters.getUserData.user?.id + "/update", par)
                .then(() => {
                  //this.$router.push("/login");
                });
            });
            localStorage.clear();
            window.location.href = "/login";
          },
    },
    getters: {
        getToken(state) {
            if (state.token) {
                return state.token;
            } else {
                if (localStorage.getItem("frontToken")) {
                    state.token = localStorage.getItem("frontToken");
                    return state.token;
                } else {
                    return null;
                }
            }
        },
        getUserData(state) {
            if (state.userData.user == undefined) return JSON.parse(localStorage.getItem("userData"));
            else return state.userData;
        },
        getLoginData(state) {
            return state.loginData;
        },
        getCompanyData(state) {
            if (state.companyData == undefined) return undefined
            else return state.companyData;
        },
    },
})
export default store