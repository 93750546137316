<template>
  <div
    class="
      engage-toolbar
      d-flex
      position-fixed
      px-5
      fw-bolder
      zindex-2
      top-50
      end-0
      transform-90
      mt-20
      gap-2
    "
  >
    <router-link
      to="/create/departments"
      class="
        engage-purchase-link
        btn btn-color-gray-700
        bg-body
        btn-active-color-gray-900' btn-flex
        h-35px
        px-5
        shadow-sm
        rounded-top-0
      "
      >Yeni Müşteri</router-link
    >
    <router-link
      to="/financial"
      class="
        engage-purchase-link
        btn btn-color-gray-700
        bg-body
        btn-active-color-gray-900' btn-flex
        h-35px
        px-5
        shadow-sm
        rounded-top-0
      "
      >Finans</router-link
    >
    <a
      href="/support"
      class="
        engage-purchase-link
        btn btn-color-gray-700
        bg-body
        btn-active-color-gray-900' btn-flex
        h-35px
        px-5
        shadow-sm
        rounded-top-0
      "
      >Yardım</a
    >
    <a
      href="/profile"
      class="
        engage-purchase-link
        btn btn-color-gray-700
        bg-body
        btn-active-color-gray-900' btn-flex
        h-35px
        px-5
        shadow-sm
        rounded-top-0
      "
      >Hesap</a
    >
  </div>
</template>