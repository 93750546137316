import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Anasayfa",
    component: () => import("@/views/HomePage.vue"),
    meta: {
      title: "Ana sayfa",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "Giriş",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
    meta: {
      title: "Kayıt Ol",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/404.vue"),
    meta: {
      title: "404",
    },
  },
  {
    path: "/create/departments",
    name: "Müşteri Oluştur",
    component: () => import("@/views/Creates.vue"),
    meta: {
      title: "Müşteri Oluştur",
    },
  },
  {
    path: "/create/hakedis",
    name: "Hakediş Oluştur",
    component: () => import("@/views/FinancialCreate.vue"),
    meta: {
      title: "Hakediş Oluştur",
    },
  },
  {
    path: "/ticket/:id",
    name: "Ticket Oluştur",
    component: () => import("@/views/Tickets.vue"),
    meta: {
      title: "Ticket Oluştur",
    },
  },
  {
    path: "/list",
    name: "Müşteriler",
    component: () => import("@/views/Lists.vue"),
    meta: {
      title: "Müşteriler",
    },
  },
  {
    path: "/list/:id",
    name: "Ödeme",
    component: () => import("@/views/Pricing.vue"),
    meta: {
      title: "Ödeme",
    },
  },
  {
    path: "/campaigns",
    name: "Kampanyalar",
    component: () => import("@/views/Campaigns.vue"),
    meta: {
      title: "Kampanyalar",
    },
  },
  {
    path: "/kayit-onaylandi",
    name: "Kayit Onay",
    component: () => import("@/views/Regisgo.vue"),
    meta: {
      title: "Kayit Onay",
    },
  },
  {
    path: "/education",
    name: "Eğitim",
    component: () => import("@/views/Education.vue"),
    meta: {
      title: "Eğitim",
    },
  },
  {
    path: "/financial",
    name: "Finansal İşlemler",
    component: () => import("@/views/Financial.vue"),
    meta: {
      title: "Finansal İşlemler",
    },
  },
  {
    path: "/profile",
    name: "Profil",
    component: () => import("@/views/Profile.vue"),
    meta: {
      title: "Profil",
    },
  },
  {
    path: "/support",
    name: "Destek",
    component: () => import("@/views/Support.vue"),
    meta: {
      title: "Destek",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " | ishesap";
  next();
});

export default router;
